import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "list-infos" }
const _hoisted_3 = { class: "info-number bloc-number" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "info-title"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      "is-home": false,
      title: "Questions sur le dépannage",
      icon: "message-circle"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Tabs, { tabsList: _ctx.TabsList }, null, 8, ["tabsList"]),
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infos, (info, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            class: "info-item"
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(i + 1), 1),
            _createElementVNode("img", {
              src: require('@/assets/reflexes/' + info.image),
              class: "info-image",
              alt: info.alt
            }, null, 8, _hoisted_4),
            (info.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(info.title), 1))
              : _createCommentVNode("", true),
            (info.content)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  class: "info-content",
                  innerHTML: info.content
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ], 64))
}