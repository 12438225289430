
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tabs',
  setup() {
    return {}
  },
  props: {
    tabsList: { type: Array },
    indexActive: { type: Number, default: 0 }
  }
})
